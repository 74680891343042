import React from 'react';
import Article from '../Article';
import { PureNavigation } from '../../components/Navigation/Navigation';
import { PurePageFooter } from '../../components/PageFooter/PageFooter';
import NotFound from '../NotFound';
import { css, ThemeProvider } from 'styled-components';
import themeWS10 from '@vfuk/core-theme-ws10';
import PageBuilder from '../page-builder';
import { convertContentfulPageBuilderSectionsToGatsbyPageBuilder } from './pageBuilder/pageBuilderDataNormalize';

themeWS10.setBaseAssetLocation('/assets/');
themeWS10.setAssetLocations('icons', 'icons/');
themeWS10.setAssetLocations('fonts', 'ws10/fonts/');

const labelCSS = css`
  padding: 0.3em 0.4em;
  margin: 1em 0 1em 0;
  color: #00acc1;
  background-color: #fff9c4;
  border: 1px solid #e3ddac;
  border-radius: 0.2em;
`;

const refreshTextCSS = css`
  padding: 0.3em 0.4em;
  color: #00acc1;
`;

const cardRowContainer = css`
  display: flex;
  justify-content: center;
`;

const NO_VALUE = 'no value';

const CreateComponentPreview = ({
  contentModelName,
  data,
  fullData,
  recaptchaToken,
  tagCategoryCollection,
}) => {
  // NB - data is the data returned under the contentModelName, and used for most components.
  // The fullData is used for more complex components that use pageContext and location as well
  // as the basic content model data, and includes components such as Article.
  const name = data.name ? ` ${data.name}` : '';
  const label = (
    <p css={labelCSS}>
      Preview of {contentModelName}
      <strong>{name}</strong>
    </p>
  );
  let componentPreview = null;

  switch (contentModelName) {
    /**
     * Section Elements
     */

    case 'sectionElementCard':
      if (data.cardSummary) {
        data.cardSummary.raw = JSON.stringify(data.cardSummary.json);
      } else {
        data.cardSummary = { raw: '' };
      }
      data.mainImage = makeGatsbyImageData(data.mainImage);
      data.iconsAndText = data.iconsAndTextCollection.items;
      componentPreview = (
        <div css={cardRowContainer}>
          <Card data={data} />
        </div>
      );
      break;

    case 'sectionElementCtaButton':
      data = nestProperties(data, ['customAttributeData', 'seoEventData']);
      componentPreview = (
        <CtaButtonRow buttonRowAlignment="left" buttons={[data]} />
      );
      break;

    case 'sectionElementCtaButtonRow':
      data.buttonsCollection.items = data.buttonsCollection.items.map(button =>
        nestProperties(button, ['customAttributeData', 'seoEventData'])
      );
      componentPreview = (
        <CtaButtonRow
          buttonRowAlignment={data.buttonRowAlignment}
          isMobileViewButtonOrderReversed={data.isMobileViewButtonOrderReversed}
          buttons={data.buttonsCollection.items}
        />
      );
      break;

    case 'sectionElementHeader':
      componentPreview = (
        <Header
          content={{
            headerText: data.headerText,
            level: data.level,
            isCenterAligned: data.isCenterAligned,
            isLeading: data.isLeading,
            isTrailing: data.isTrailing,
          }}
        />
      );
      break;

    case 'sectionElementRichText':
      data.id = data.sys.id;
      data.color = data.color || '';
      data.textAlign = data.textAlign || 'center';
      data.text.raw = JSON.stringify(data.text.json);
      componentPreview = (
        <RichText
          key={data.id}
          rawRichText={data.text.raw}
          color={data.color}
          textAlign={data.textAlign}
        />
      );
      break;

    case 'sectionElementVideoArticle':
      data.videoSummary.raw = JSON.stringify(data.videoSummary.json);
      componentPreview = (
        <div css={cardRowContainer}>
          <VideoSummary data={data} />
        </div>
      );
      break;

    /**
     * Sections
     */

    case 'sectionHeroWithTextAndCta':
      data.id = data.sys.id;
      data.textPosition = data.textPosition || 'center';
      data.content = data.contentCollection.items.map(data => {
        data.__typename = `Contentful${data.__typename}`;
        switch (data.__typename) {
          case 'ContentfulSectionElementRichText':
            data.id = data.sys.id;
            data.color = data.color || '';
            data.textAlign = data.textAlign || 'center';
            data.text.raw = JSON.stringify(data.text.json);
            break;

          case 'ContentfulSectionElementCtaButtonRow':
            data.buttons = data.buttonsCollection.items;
            data.buttons = data.buttons.map(button =>
              nestProperties(button, ['customAttributeData', 'seoEventData'])
            );
            break;

          default:
          // do nothing
        }
        return data;
      });
      data.responsiveImageCollection =
        data.responsiveImageCollectionCollection.items;
      data.responsiveImageCollection = data.responsiveImageCollection.map(
        data => {
          data.imageTitle.file = { url: data.imageTitle.url };
          if (data?.secondImage?.url) {
            data.secondImage.file = { url: data.secondImage.url };
          }
          return data;
        }
      );
      componentPreview = (
        <SectionHero
          key={data.id}
          id={data.id}
          __typename={data.__typename}
          name={data.name}
          textPosition={data.textPosition}
          content={data.content}
          responsiveImageCollection={data.responsiveImageCollection}
        />
      );
      break;

    case 'sectionCustomStepper':
      data.id = data.sys.id;
      data.name = data.name || NO_VALUE;
      data.title = data.title || NO_VALUE;
      data.text = data.text || NO_VALUE;
      data.content = data.contentCollection.items;
      data.content = data.content.map(data => {
        data.stepImage.file = { url: data.stepImage.url };
        return data;
      });
      componentPreview = (
        <SectionCustomStepper
          key={data.id}
          id={data.id}
          __typename={data.__typename}
          name={data.name}
          title={data.title}
          text={data.text}
          content={data.content}
        />
      );
      break;

    case 'sectionTitledArticleIndexList':
      componentPreview = <p>To do - sectionTitledArticleIndexList</p>;
      break;

    /**
     * Other
     */

    case 'pageFooter':
      data.footerLinksCollection.items = data.footerLinksCollection.items.map(
        links => {
          links.links = links.linksCollection.items.map(link => {
            link.id = link.sys.id;
            return link;
          });
          return links;
        }
      );
      data.copyrightText = { copyrightText: data.copyrightText };

      componentPreview = (
        <PurePageFooter
          footerLinks={data.footerLinksCollection.items}
          copyrightText={data.copyrightText}
        />
      );
      break;

    case 'seo404Page':
      componentPreview = (
        <NotFound heading={data.heading} bodyObject={data.body.json} />
      );
      break;

    case 'navigation':
      data.mainLinksCollection.items = data.mainLinksCollection.items.map(
        item => {
          item.contentful_id = item.sys.id;
          return item;
        }
      );
      data.topLevelLinksCollection.items =
        data.topLevelLinksCollection.items.map(item => {
          item.contentful_id = item.sys.id;
          return item;
        });
      data.mainLinksCollection.items = data.mainLinksCollection.items.map(
        item => {
          item.isSelected = item.isSelected || false;
          return item;
        }
      );
      componentPreview = (
        <div
          css={css`
            padding-top: 4px;
          `}
        >
          <PureNavigation
            mainLinks={data.mainLinksCollection.items}
            topLevelLinks={data.topLevelLinksCollection.items}
            brandLink={data.brandLink}
          />
        </div>
      );
      break;

    case 'pageArticle':
      const {
        allFilterableArticles,
        //articleIndex,
        //pageArticle,
        contentfulSiteConfig,
        pageBackgroundColorsCollection,
        allContentfulConfigArticlePages
      } = fullData.data;

      allFilterableArticles.items = allFilterableArticles.items.map(article =>
        convertContentfulArticleToGatsbyArticle(article)
      );
      fullData.data.pageArticle = convertContentfulArticleToGatsbyArticle(
        fullData.data.pageArticle
      );

      let formattedSiteConfig;
      contentfulSiteConfig.items.forEach(item => {
        formattedSiteConfig = {
          ...item,
          sharingsocialMediaButtons: item.sharingsocialMediaButtons || [],
        };
      });

      const formattedAllFilterableArticles = allFilterableArticles.items.map(
        article => ({
          ...article,
          createdAt: new Date(article.sys.createdAt).toLocaleDateString(
            'en-GB'
          ),
          updatedAt: new Date(article.sys.updatedAt).toLocaleDateString(
            'en-GB'
          ),
          publishingDate: new Date(article.publishingDate || article.sys.createdAt).toLocaleDateString(
            'en-GB'
          ),
        })
      );

      let mainCategorySlug = fullData.data.pageArticle.categoryTags[0]?.name;

      tagCategoryCollection.some(contentTag => {
        const isFound =
          contentTag.name === fullData.data.pageArticle.categoryTags[0]?.name;
        if (isFound && contentTag.slugName) {
          mainCategorySlug = contentTag.slugName;
        }
        return isFound;
      });

      const pageContext = {
        allFilterableArticles: formattedAllFilterableArticles,
        categoryTagName:
          fullData.data.pageArticle.categoryTags[0]?.name || NO_VALUE,
        categorySlugName:
          mainCategorySlug || fullData.data.pageArticle.categoryTags[0]?.name,
        articleIndex: {
          name: fullData.data.articleIndex.items[0]?.name || 'no name',
          slug: fullData.data.articleIndex.items[0]?.slug || 'no-slug',
        },
        homePageName:
          fullData.data.articleIndex.items[0]?.homePageName ||
          'no home page name',
        externalPageName:
          fullData.data.articleIndex.items[0]?.externalPageName || null,
        externalPageUrl:
          fullData.data.articleIndex.items[0]?.externalPageUrl || null,
        recommendedArticlesContainer: {
          name: fullData.data.recommendedArticles.items[0].name,
          content: [
            resolveContentfulSectionElementHeader(
              fullData.data.recommendedArticles.items[0].contentCollection
                .items[0]
            ),
            resolveContentfulSectionElementCtaButtonRow(
              fullData.data.recommendedArticles.items[0].contentCollection
                .items[1]
            ),
          ],
          __typename: 'ContentfulSectionElementTitledRecommendedArticles',
        },
        globalBackgroundColors: {
          name: pageBackgroundColorsCollection.items[0]?.name || NO_VALUE,
          oddColor: pageBackgroundColorsCollection.items[0]?.oddColor || 'N/A',
          evenColor:
            pageBackgroundColorsCollection.items[0]?.evenColor || 'N/A',
        },
        configArticlePages: convertToConfigArticlePages(allContentfulConfigArticlePages.items[0]),
      };

      const theData = {
        contentfulPageArticle: fullData.data.pageArticle,
        contentfulSiteConfig: formattedSiteConfig,
      };
      const location = { pathname: '' };

      componentPreview = (
        <>
          <br />
          <Article
            pageContext={pageContext}
            data={theData}
            location={location}
          />
          <br />
        </>
      );
      break;

    case 'pageBuilder': {
      const { pageBuilder } = fullData.data;

      const {
        name,
        seoDescription,
        oddColor,
        seoTitle,
        evenColor,
        sectionsCollection,
      } = pageBuilder;

      const formattedSections = sectionsCollection.items.map(section =>
        convertContentfulPageBuilderSectionsToGatsbyPageBuilder(section)
      );

      const pageContext = {
        pageBuilderData: {
          seoTitle: seoTitle,
          seoDescription: seoDescription,
          sections: formattedSections,
          oddColor: oddColor || '',
          evenColor: evenColor || '',
          nodeLocale: 'en',
        },
        recaptchaToken,
      };

      const location = {};

      componentPreview = (
        <>
          <br />
          <PageBuilder pageContext={pageContext} location={location} />
          <br />
        </>
      );
      break;
    }

    default:
    // do nothing
  }

  if (componentPreview) {
    return (
      <ThemeProvider theme={themeWS10}>
        {label}
        {componentPreview}
        <p css={refreshTextCSS}>
          <a href="javascript:window.location.href=window.location.href">
            <strong>
              <u>Refresh this page</u>
            </strong>
          </a>{' '}
          to see latest version.
        </p>
      </ThemeProvider>
    );
  }
  return <p>{`Can't find a preview for ${contentModelName}`}</p>;
};

const nestProperties = (data, propertyNames) => {
  propertyNames.forEach(propertyName => {
    if (
      propertyName in data &&
      (data[propertyName] === null || typeof data[propertyName] !== 'object')
    ) {
      const nest = {};
      nest[propertyName] = data[propertyName];
      data[propertyName] = nest;
    }
  });
  return data;
};

function makeGatsbyImageData(data) {
  const imageUrl = data.url;
  const imageData = {
    ...data,
    ...{ gatsbyImageData: { images: { sources: [] } } },
  };
  imageData.gatsbyImageData.images.sources.push({ srcSet: imageUrl });
  return imageData;
}

function convertToConfigArticlePages(configArticlePages) {
  return {
    ...configArticlePages,
    stickyHeader: {
      content: configArticlePages.stickyHeader.contentCollection.items.map(content => {
        if (content.__typename === 'SectionElementRichText') {
          return {
            ...content,
            __typename: `Contentful${content.__typename}`,
            text: {
              raw: JSON.stringify(content.text.json)
            }
          }
        } else {
          return {
            ...content,
            __typename: `Contentful${content.__typename}`,
          }
        }
      })
    }
  }
}

function convertContentfulArticleToGatsbyArticle(article) {
  if (article.mainImage) {
    const gatsbyImage = {
      images: { fallback: { src: article.mainImage.url } },
    };
    article.mainImage.gatsbyImageData = gatsbyImage;
  }

  if (article.body) {
    article.body.raw = JSON.stringify(article.body.json);
    article.body.references = [];

    if (article.body.links) {
      const embededImages = article.body.links.assets.block;

      embededImages.map(img => {
        article.body.references = [
          ...article.body.references,
          {
            gatsbyImageData: {
              ...img,
              layout: 'fullWidth',
              images: {
                fallback: {
                  src: img.url,
                },
              },
              width: img.width,
              height: img.height,
            },
            contentful_id: img.sys.id,
            file: {
              url: img.url,
            },
          },
        ];
      });
    }
  }
  if (article.quickSummaryCollection) {
    const quickSummary = article.quickSummaryCollection.items.length ?
      {
        title: article.quickSummaryCollection.items[0].title,
        quickSummaryItems: article.quickSummaryCollection.items[0].quickSummaryItemsCollection.items.length ?
          article.quickSummaryCollection.items[0].quickSummaryItemsCollection.items.map(quickSummaryItems => ({
            ...quickSummaryItems
          }))
          : []
      }
      : {}
    article.quickSummary = quickSummary
  }

  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  article.categoryTags = article.categoryTagsCollection.items;
  article.topicTags = article.topicTagsCollection.items;
  article.customMetadata = article?.customMetadataCollection?.items || [];
  article.scripts = article?.scriptsCollection?.items || [];
  article.__typename = 'Contentful' + article.__typename;
  article.createdAt = new Date(article.sys.createdAt).toLocaleDateString(
    'en-GB', options
  );
  article.updatedAt = new Date(article.sys.updatedAt).toLocaleDateString(
    'en-GB', options
  );
  return article;
}

function resolveContentfulSectionElementHeader(source) {
  return {
    __typename: 'ContentfulSectionElementHeader',
    id: source.sys.id,
    name: source?.name || 'no_name',
    headerText: source?.headerText || 'no_content',
    appearance: source?.appearance || 'secondary',
    inverse: source?.inverse || false,
    justify: source?.justify || 'center',
    level: source?.level || 2,
    noMargin: source?.noMargin || false,
    weight: source?.weight || null,
    isLeading: source?.isLeading || false,
    isTrailing: source?.isTrailing || false,
    hasNoGutter: source?.hasNoGutter || false,
    fontWeight: source?.fontWeight || 'bold',
  };
}

function resolveContentfulSectionElementCtaButtonRow(source) {
  return {
    __typename: 'ContentfulSectionElementCtaButtonRow',
    id: source?.sys.id || 'no_name',
    buttonRowAlignment: 'centre',
    buttons: [
      {
        id: source?.buttons.items[0].sys.id || 'no_name',
        name: source?.buttons.items[0].name || 'no_name',
        label: source?.buttons.items[0].label || 'Submit',
        mobileLabel: source?.buttons.items[0].mobileLabel || 'no_content',
        url: source?.buttons.items[0].url || 'no_url',
        iconRef: source?.buttons.items[0].iconRef || 'no_content',
        rank: source?.buttons.items[0].rank || 'no_content',
        mobileViewRank: source?.buttons.items[0].mobileViewRank || 'no_content',
        inverse: source?.buttons.items[0].inverse || false,
        mobileViewInverse: source?.buttons.items[0].mobileViewInverse || false,
        linkType: source?.buttons.items[0].linkType || 'no_content',
        buttonAlignment: source?.buttons.items[0].buttonAlignment || '',
        tooltipLabel: source?.buttons.items[0].tooltipLabel || '',
        tooltipLabelColor: source?.buttons.items[0].tooltipLabelColor || '',
        tooltipBackgroundColor:
          source?.buttons.items[0].tooltipBackgroundColor || '',
      },
    ],
  };
}

export default CreateComponentPreview;
